<template>
  <div>
    <div class="center-block-date">
      <Select v-model="queryModel.startDate" style="width:100px" size="small" @on-change="handleChangeDate">
        <Option v-for="(date,index) in startDateArray" :key="index" :value="date.key">{{date.value}}</Option>
      </Select>
      <span class="m-l-5 m-r-5">至</span>
      <Select v-model="queryModel.endDate" style="width:100px" size="small" @on-change="handleChangeDate">
        <Option v-for="(date,index) in endDateArray" :key="index" :value="date.key">{{date.value}}</Option>
      </Select>
    </div>

    <!-- 显示地铁线路图 -->
    <SvgLine v-if="showMapType===1" ref="svgline" @map-finished="handleMapLoadFinished"/>
    <GeoMap v-else ref="geoMap" @map-finished="handleMapLoadFinished"/>

    <!-- 合同详细浮窗 -->
    <div v-if="contractBean" class="subject-remark">
      <h3 class="text-center p-t-10 p-b-10">{{contractTypeName}}详情</h3>

      <Row class="p-b-5 p-l-5">
        <i-col span="8">{{contractTypeName}}编号：</i-col>
        <i-col span="16">{{contractBean.code}}</i-col>
      </Row>
      <Row class="p-b-5 p-l-5">
        <i-col span="8">所属行业：</i-col>
        <i-col span="16">{{contractBean.industryName}}</i-col>
      </Row>
      <Row class="p-b-5 p-l-5">
        <i-col span="8">广告主：</i-col>
        <i-col span="16">{{contractBean.advertiserName}}</i-col>
      </Row>
      <Row class="p-b-5 p-l-5">
        <i-col span="8">甲方：</i-col>
        <i-col span="16">{{contractBean.firstParty}}</i-col>
      </Row>
      <Row class="p-b-5 p-l-5" v-if="publisherId !== 21">
        <i-col span="8">签约折扣：</i-col>
        <i-col span="16">{{contractBean.discountRate}} %</i-col>
      </Row>
      <Row class="p-b-5 p-l-5">
        <i-col span="8">发布档期：</i-col>
        <i-col span="16">{{contractBean.startDate}} - {{contractBean.endDate}}</i-col>
      </Row>
      <Row class="text-center">
        <i-col span="24">
          <a @click="handleShowContractAttachment">查看{{contractTypeName}}附件</a>
        </i-col>
      </Row>
    </div>

    <Modal v-model="modalContractAttachment" :title="contractTypeName+'附件'" :footer-hide="true" width="800">
        <files :fileList="signfiles" :downloadUrl="'/ooh-scp/v1/contractfile/downloadfile'"></files>
    </Modal>
  </div>
</template>

<script>
import SvgLine from '@/components/svg/SvgLine'
import GeoMap from '@/components/map/GeoMap'
import Files from '@/components/common/FilesV2'

import { getStartDateList, getEndDateList } from '@/api/dw/time'
import { getContractById, listCompanyAssets } from '@/api/dw/datav'
import { getFileList } from '@/api/scp/contractfile'
import { getOrderStationList } from '@/api/order/order'

export default {
  components: {
    SvgLine, GeoMap, Files
  },
  data () {
    return {
      showMapType: this.$store.getters.token.publisherSetting.mediaType,
      publisherId: this.$store.getters.token.userInfo.publisherId,
      loginCompanyId: this.$store.getters.token.userInfo.companyId,
      mapFinished: false,
      startDateArray: [],
      endDateArray: [],

      queryModel: {
        endDate: '',
        startDate: ''
      },

      contractBean: null,
      contractTypeName: '',
      signfiles: [],
      modalContractAttachment: false
    }
  },
  created () {
    this.queryModel.startDate = this.searchSchedule.startDate
    this.queryModel.endDate = this.searchSchedule.endDate
    this.initPageData()
  },
  methods: {
    initPageData () {
      const postData = { publisherId: this.publisherId }
      getStartDateList(postData).then(res => {
        this.startDateArray = res
      })
      getEndDateList(postData).then(res => {
        this.endDateArray = res
      })
    },
    handleChangeDate () {
      const schedule = {
        startDate: this.queryModel.startDate,
        endDate: this.queryModel.endDate
      }

      // this.$store.commit('set_situation_companyId', (this.loginCompanyId === this.publisherId) ? '0' : this.loginCompanyId)

      this.$store.commit('set_situation_searchSchedule', schedule)
    },
    handleMapLoadFinished (val) {
      this.mapFinished = val

      if (val) {
        // 执行线路隐藏操作
        listCompanyAssets({ publisherId: this.publisherId }).then(res => {
          const assetIds = []
          let relateIds = []
          res.forEach(item => {
            relateIds = item.relateIds.split(',').map(x => parseInt(x))

            relateIds.forEach(element => {
              if (!assetIds.includes(element)) {
                assetIds.push(element)
              }
            })
          })

          this.$refs.svgline.hideOtherAsset(assetIds)
        })
      }
    },
    loadContractInfo (subjectId) {
      getContractById({ subjectId: subjectId }).then(res => {
        this.contractBean = res
        this.contractTypeName = (res.type === 4) ? '结算单' : '合同'
        this.loadContractStationInfo(res.orderIds)
      })
    },
    handleShowContractAttachment () {
      this.signfiles = []
      this.modalContractAttachment = true
      const downloadType = (this.contractTypeName === '结算单') ? 'STATEMENT_MANAGE_ATTACHMENT_FILE' : 'CONTRACT_SIGN_FILE'

      getFileList({ relateId: this.chooseRightContractId, type: downloadType }).then(res => {
        this.signfiles = res.map(x => {
          return Object.assign({}, x, { url: process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/contractfile/downloadfile?fileId=' + x.id })
        })
      })
    },
    loadContractStationInfo (orderIds) {
      if (orderIds.length === 0) {
        if (this.showMapType === 1) {
          this.$refs.svgline.loadFlickerStations([])
        } else {
          this.$refs.geoMap.loadFlickerStations([])
        }
        return
      }

      const query = { orderIds: JSON.stringify(orderIds) }

      getOrderStationList(query).then(res => {
        const tagStationIds = res.map(x => x.stationId)
        if (this.showMapType === 1) {
          this.$refs.svgline.loadFlickerStations(tagStationIds, false)
        } else {
          this.$refs.geoMap.loadFlickerStations(tagStationIds)
        }
      })
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    chooseRightContractId () {
      return this.$store.state.situationWeb.rightTableId
    }
  },
  watch: {
    chooseRightContractId (val) {
      if (val) {
        // 开始加载合同信息
        this.loadContractInfo(val)
      } else {
        this.contractBean = null
        if (this.showMapType === 1) {
          this.$refs.svgline.loadFlickerStations([])
        } else {
          this.$refs.geoMap.loadFlickerStations([])
        }
      }
    }
  }
}
</script>

<style>
.subject-remark{
  position:absolute;
  top: 80px;
  left: 10px;
  width: 200px;
  background-color:rgba(0,0,0,0.2);
}
</style>
